import { render, staticRenderFns } from "./FeatureSectionColumns.vue?vue&type=template&id=cb790cfe&scoped=true"
import script from "./FeatureSectionColumns.vue?vue&type=script&setup=true&lang=js"
export * from "./FeatureSectionColumns.vue?vue&type=script&setup=true&lang=js"
import style0 from "./FeatureSectionColumns.vue?vue&type=style&index=0&id=cb790cfe&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb790cfe",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiTitle: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/ASBIS/ASBIS_Deploy_Prod@6/components/ui/UiTitle.vue').default,UiDescription: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/ASBIS/ASBIS_Deploy_Prod@6/components/ui/UiDescription.vue').default,UiButton: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/ASBIS/ASBIS_Deploy_Prod@6/components/ui/UiButton.vue').default,UiSection: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/ASBIS/ASBIS_Deploy_Prod@6/components/ui/UiSection.vue').default})
