
import { defineComponent, ref } from '@nuxtjs/composition-api';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import SolutionsCarouselSlide from '@/domains/sliders/components/SolutionsCarouselSlide.vue';

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
    SolutionsCarouselSlide,
  },
  props: {
    slides: {
      type: Array,
      default: () => [],
    },
  },
  setup(_props, { emit }) {
    const swiper = ref();
    const swiperOptions = {
      observer: true,
      navigation: {
        prevEl: '.nav-prev',
        nextEl: '.nav-next',
        disabledClass: 'group-hover:opacity-50 group-hover:hover:opacity-75',
      },
      on: {
        activeIndexChange: () => {
          emit('slide-change', swiper.value.$swiper.activeIndex);
        },
      },
    };

    const playVideo = (videoId: string) => {
      emit('play-video', videoId);
    };

    return {
      swiper,
      swiperOptions,
      playVideo,
    };
  },
});
