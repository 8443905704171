
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useBreakpoint, useMediaAsset } from '~/composables';

export default defineComponent({
  props: {
    id: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: '',
    },
    backgroundImageDesktop: {
      type: String,
      default: '',
    },
    backgroundImageMobile: {
      type: String,
      default: '',
    },
    button1: {
      type: Object,
      default: () => ({}),
    },
    button2: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { getAssetUrl } = useMediaAsset();
    const { isBiggerThanBreakpoint: isBiggerSm } = useBreakpoint('sm');
    const { isBiggerThanBreakpoint: isBiggerMd } = useBreakpoint('md');

    const sectionBackground = computed(() =>
      getAssetUrl(
        isBiggerMd.value
          ? props.backgroundImageDesktop
          : props.backgroundImageMobile,
      ),
    );

    return {
      isBiggerSm,
      isBiggerMd,
      sectionBackground,
    };
  },
});
