import { CdnImg } from '@webplatform/cdn-image';


export default {
  __name: 'FeatureSectionColumns',
  props: {
  id: {
    type: String,
    default: undefined,
  },
  title: {
    type: String,
    default: '',
  },
  text: {
    type: String,
    default: '',
  },
  logoImage: {
    type: String,
    default: '',
  },
  boardImage: {
    type: String,
    default: '',
  },
  columns: {
    type: Array,
    default: () => [],
  },
  button: {
    type: Object,
    default: () => ({}),
  },
},
  setup(__props) {



return { __sfc: true,CdnImg }
}

}