
import { defineComponent, computed } from '@nuxtjs/composition-api';

const SPACING_MAP = {
  top: {
    lg: 'pt-8 md:pt-16',
    md: 'pt-8 md:pt-8',
    sm: 'md:pt-8',
  },
  bottom: {
    lg: 'pb-8 md:pb-16',
    md: 'pb-8 md:pb-8',
  },
};

export default defineComponent({
  props: {
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    spacing: {
      type: Object,
      default: () => ({ top: 'lg', bottom: 'lg' }),
    },
  },
  setup(props) {
    const classes = computed(() => [
      SPACING_MAP.top[props.spacing.top],
      SPACING_MAP.bottom[props.spacing.bottom],
    ]);

    return {
      classes,
    };
  },
});
