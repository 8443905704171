
import { defineComponent } from '@nuxtjs/composition-api';
import { useBreakpoint, useMediaAsset } from '~/composables';
import { CdnImg } from '@webplatform/cdn-image';

const gradients = [
  'from-[#432A9E] to-[#2050A7]',
  'from-[#635E78] to-[#485876]',
];

export default defineComponent({
  components: {
    CdnImg,
  },
  props: {
    id: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: '',
    },
    plans: {
      type: Array,
      default: () => [],
    },
    footer: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { getAssetUrl } = useMediaAsset();

    const { isBiggerThanBreakpoint: isDesktop } = useBreakpoint('md');

    const getPlanHeaderBg = (index: number) => gradients[index];

    return {
      isDesktop,
      getAssetUrl,
      getPlanHeaderBg,
    };
  },
});
