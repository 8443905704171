import { render, staticRenderFns } from "./FormSectionPartnership.vue?vue&type=template&id=5c10a77c"
import script from "./FormSectionPartnership.vue?vue&type=script&lang=ts"
export * from "./FormSectionPartnership.vue?vue&type=script&lang=ts"
import style0 from "./FormSectionPartnership.vue?vue&type=style&index=0&id=5c10a77c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiInput: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/ASBIS/ASBIS_Deploy_Prod@6/components/ui/UiInput.vue').default,UiMultiselect: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/ASBIS/ASBIS_Deploy_Prod@6/components/ui/UiMultiselect.vue').default,UiTextarea: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/ASBIS/ASBIS_Deploy_Prod@6/components/ui/UiTextarea.vue').default,UiCheckbox: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/ASBIS/ASBIS_Deploy_Prod@6/components/ui/UiCheckbox.vue').default,UiLink: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/ASBIS/ASBIS_Deploy_Prod@6/components/ui/UiLink.vue').default,UiButton: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/ASBIS/ASBIS_Deploy_Prod@6/components/ui/UiButton.vue').default,UiSection: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/ASBIS/ASBIS_Deploy_Prod@6/components/ui/UiSection.vue').default})
