
import { defineComponent, useContext, useFetch } from '@nuxtjs/composition-api';
import { useFetchPage } from '@webplatform/nuxt-cms-js-sdk';
import PageBlocks from '@/components/PageBlocks.vue';

export default defineComponent({
  components: {
    PageBlocks,
  },
  setup() {
    const { i18n } = useContext();
    const { page, fetchPage } = useFetchPage('main', {
      lang: i18n.locale,
    });

    useFetch(async () => {
      await fetchPage();
    });

    return {
      page,
    };
  },
  head: {},
});
