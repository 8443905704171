
import {
  defineComponent,
  reactive,
  ref,
  useContext,
  useRoute,
  useFetch,
  computed,
} from '@nuxtjs/composition-api';

import { useForm, useDomain, useLanguages } from '~/composables';
import { Country } from '@webplatform/nuxt-cms-js-sdk';

const CONSULTATION_FORM_TYPE = 'asbis_consultation';

interface ConsultationForm {
  type: string;
  lead_source: string;
  source_description: string;
  lead_source_description: string;
  lang: string;
  core_reason_of_request: string;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  company_name: string;
  business_needs_pain_points_comments: string;
  marketing_yes: boolean;
  country: string;
}

export default defineComponent({
  props: {
    id: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    successTitle: {
      type: String,
      default: '',
    },
    successDescription: {
      type: String,
      default: '',
    },
    coreReasonOfRequest: {
      type: String,
      default: '1',
    },
    showCountrySelect: {
      type: Boolean,
      default: false,
    },
    showFileOnSuccess: {
      type: Boolean,
      default: false,
    },
    file: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const route = useRoute();
    const { domain } = useDomain();
    const { currentLanguage } = useLanguages();
    const { pending: formPending, submit } = useForm();
    const recaptchaClicked = ref(false);
    const {
      i18n,
      $gtm,
      $api: { countriesService },
    } = useContext();

    const formSuccess = ref(false);
    const countries = ref<Country[]>([]);

    const currentTitle = computed(() => {
      let newTitle: any = props.title;
      if (formSuccess.value) {
        newTitle = props.successTitle
          ? props.successTitle
          : i18n.t('thank_you');
      }
      return newTitle;
    });

    const currentDescription = computed(() => {
      let newDescription: any = props.description;
      if (formSuccess.value) {
        newDescription = props.successDescription
          ? props.successDescription
          : i18n.t('our_experts_will_contact_you');
      }
      return newDescription;
    });

    const formData = reactive<ConsultationForm>({
      type: CONSULTATION_FORM_TYPE,
      lead_source: `${domain}${route.value.path}`,
      source_description: domain,
      lead_source_description: `${domain}${route.value.path}`,
      lang: currentLanguage.value?.code as string,
      core_reason_of_request: props.coreReasonOfRequest,
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      company_name: '',
      business_needs_pain_points_comments: '',
      marketing_yes: false,
      country: '',
    });
    const confirmPersonalData = ref(false);
    const confirmAdvs = ref(false);
    const businesses = [
      { name: 'Industrial' },
      { name: 'Cleaning' },
      { name: 'Service' },
      { name: 'Security' },
      { name: 'Logistics' },
      { name: 'Grippers' },
      { name: 'Delivery' },
    ];

    const fetchCountries = async () => {
      const { data } = await countriesService.getAllCountries({
        limit: 9999,
        // @ts-ignore
        it4: 1,
      });
      countries.value = data;
    };
    useFetch(async () => {
      await fetchCountries();
    });
    const submitForm = async () => {
      $gtm.push({ event: 'form-section-consultation' });

      const sentFormData: Partial<ConsultationForm> = { ...formData };
      if (!props.showCountrySelect) delete sentFormData.country;
      try {
        await submit(sentFormData);
        confirmPersonalData.value = false;
        formSuccess.value = true;
      } catch (e) {
        console.error(e);
      }
    };

    return {
      currentTitle,
      currentDescription,
      formData,
      confirmPersonalData,
      confirmAdvs,
      formSuccess,
      formPending,
      submitForm,
      recaptchaClicked,
      businesses,
      countries,
    };
  },
});
