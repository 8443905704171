
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { Dropdown } from 'floating-vue';
import { useBreakpoint } from '~/composables';

export default defineComponent({
  components: {
    Dropdown,
  },
  props: {
    marker: {
      type: Object,
      default: () => ({}),
    },
    distance: {
      type: [String, Number],
      default: 16,
    },
    placement: {
      type: String,
      default: 'left',
    },
  },
  setup(props) {
    const { isBiggerThanBreakpoint: isDesktop } = useBreakpoint('md');

    // @TODO: Simplify after deploying to master and changing json format of block at homepage
    const markerPosition = computed(() =>
      !props.marker.position.desktop
        ? props.marker.position
        : (!isDesktop.value && props.marker.position.mobile) ||
          props.marker.position.desktop,
    );

    return {
      isDesktop,
      markerPosition,
    };
  },
});
