
import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import { useMediaAsset } from '~/composables';

export default defineComponent({
  props: {
    slide: {
      type: Object,
      default: undefined,
    },
  },
  setup(props) {
    const { getAssetUrl } = useMediaAsset();

    const { title, text, image, videoId, features, featuresText } = props.slide;

    const bgImage = computed(() =>
      image ? `url(${getAssetUrl(image)})` : null,
    );

    const isPlaying = ref(false);

    const playVideo = () => {
      isPlaying.value = true;
    };

    return {
      title,
      text,
      videoId,
      features,
      featuresText,
      bgImage,
      isPlaying,
      getAssetUrl,
      playVideo,
    };
  },
});
