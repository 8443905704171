
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    id: {
      type: String,
      default: '',
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
  },
});
