
import {
  defineComponent,
  onMounted,
  ref,
  useContext,
} from '@nuxtjs/composition-api';
import { CdnImg } from '@webplatform/cdn-image';

export default defineComponent({
  components: {
    CdnImg,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { $gsap, $ScrollTrigger } = useContext();
    const section = ref();
    const imageWrap = ref();
    const block = ref();
    const activeTextChunk = ref(0);
    const activeImageStyle = ref<null | object>(null);

    onMounted(() => {
      const contentChunks: HTMLElement[] = [...block.value.children];

      const tl = $gsap.timeline({
        scrollTrigger: {
          trigger: block.value,
          start: 'top top',
          end: '+=2500',
          scrub: 1,
          toggleActions: 'restart none none reverse',
        },
      });

      contentChunks.forEach((chunk, i) => {
        if (i !== 0) {
          tl.fromTo(
            chunk.querySelector('.text-block'),
            { yPercent: 10, autoAlpha: 0 },
            { yPercent: 0, autoAlpha: 1 },
          );

          tl.fromTo(
            chunk.querySelector('.image-block'),
            { autoAlpha: 0 },
            { autoAlpha: 1 },
            '<',
          );
        }

        if (i < contentChunks.length - 1) {
          tl.to(
            chunk.querySelector('.text-block'),
            { yPercent: -10, autoAlpha: 0 },
            '+=3',
          );
        }
      });

      $ScrollTrigger.create({
        trigger: section.value,
        start: 'center center',
        end: '+=3500',
        pin: true,
      });
    });

    return {
      section,
      imageWrap,
      block,
      activeImageStyle,
      activeTextChunk,
    };
  },
});
