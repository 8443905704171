
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useBreakpoint, useMediaAsset } from '~/composables';

export default defineComponent({
  props: {
    id: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    imageMobile: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { getAssetUrl } = useMediaAsset();
    const { isBiggerThanBreakpoint: isDesktop } = useBreakpoint('md');

    const backgroundImage = computed(() => {
      const img = isDesktop.value
        ? props.image
        : props.imageMobile ?? props.image;

      return img ? getAssetUrl(img) : '';
    });

    return {
      getAssetUrl,
      backgroundImage,
    };
  },
});
