
import { defineComponent, ref } from '@nuxtjs/composition-api';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import SolutionsCarouselSlideMobile from '@/domains/sliders/components/SolutionsCarouselSlideMobile.vue';

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
    SolutionsCarouselSlideMobile,
  },
  props: {
    slides: {
      type: Array,
      default: () => [],
    },
  },
  setup(_props, { emit }) {
    const swiper = ref();
    const swiperOptions = {
      observer: true,
      slidesPerView: 1,
      spaceBetween: 16,
      scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true,
        dragSize: 180,
        snapOnRelease: true,
      },
      on: {
        activeIndexChange: () => {
          emit('slide-change', swiper.value.$swiper.activeIndex);
        },
      },
    };

    const videoPlayer = ref();
    const videoInModal = ref<null | string>(null);

    const playVideo = (videoId: string) => {
      videoInModal.value = videoId;
      videoPlayer.value.$children[0].open();
    };

    const closeVideo = () => {
      videoInModal.value = null;
      videoPlayer.value.$children[0].close();
    };

    return {
      swiper,
      swiperOptions,
      videoPlayer,
      videoInModal,
      playVideo,
      closeVideo,
    };
  },
});
