
import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    placeholder: {
      type: String,
      default: ' ',
    },
    maxlength: {
      type: [String, Number],
      default: undefined,
    },
    fitContainer: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const valueLocal = computed({
      get() {
        return props.value;
      },
      set(newValue) {
        emit('input', newValue);
      },
    });

    return {
      valueLocal,
    };
  },
});
