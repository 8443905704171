import {
  isTouchDevice,
  kebabToPascal,
  prepareBreadcrumbs,
  prepareDynamicBlock,
  setProtocol,
} from '@webplatform/frontkit/dist/helpers';

export {
  isTouchDevice,
  kebabToPascal,
  prepareBreadcrumbs,
  prepareDynamicBlock,
  setProtocol,
};
