
import { defineComponent } from '@nuxtjs/composition-api';
import { useBreakpoint } from '~/composables';

export default defineComponent({
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { isBiggerThanBreakpoint: isDesktop } = useBreakpoint('md');

    const getItemBgStyle = (image: string) =>
      image ? { 'background-image': `url(${image})` } : null;

    return {
      isDesktop,
      getItemBgStyle,
    };
  },
});
