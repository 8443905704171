
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useBreakpoint, useMediaAsset } from '~/composables';
import { CdnImg } from '@webplatform/cdn-image';

export default defineComponent({
  components: {
    CdnImg,
  },
  props: {
    id: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    button: {
      type: Object,
      default: () => ({}),
    },
    markersBoard: {
      type: Object,
      default: () => ({}),
    },
    markers: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { getAssetUrl } = useMediaAsset();
    const { isBiggerThanBreakpoint: isDesktop } = useBreakpoint('md');

    const markersBoardImageStyles = computed(() => {
      const styles = props.markersBoard?.styles;
      return isDesktop.value ? styles?.desktop : styles.mobile;
    });

    const getBackground = (image: string) => (image ? getAssetUrl(image) : '');

    return {
      isDesktop,
      markersBoardImageStyles,
      getBackground,
    };
  },
});
