
import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    value: {
      type: [Boolean, Array],
      default: false,
    },
    checkboxValue: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    const valueLocal = computed({
      get() {
        return props.value;
      },
      set(newValue) {
        // eslint-disable-next-line vue/require-explicit-emits
        emit('input', newValue);
        // eslint-disable-next-line vue/require-explicit-emits
        emit('change', newValue);
      },
    });
    return {
      valueLocal,
    };
  },
});
