
import { computed, defineComponent, ref, watch } from '@nuxtjs/composition-api';
import { useMediaAsset } from '~/composables';
import UiRichVideo from '~/components/ui/UiRichVideo.vue';

export default defineComponent({
  name: 'AppVideo',
  components: { UiRichVideo },
  props: {
    video: {
      type: [Object, String],
      default: '',
    },
    play: {
      type: Boolean,
      default: false,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    controls: {
      type: Boolean,
      default: false,
    },
    muted: {
      type: Boolean,
      default: true,
    },
    loop: {
      type: Boolean,
      default: true,
    },
    containerSize: {
      type: Boolean,
      default: false,
    },
    videoClass: {
      type: String,
      default: '',
    },
    lazy: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { getAssetUrl } = useMediaAsset();
    const videoRef = ref();

    const getYoutubeId = (value = '') => {
      if (value) {
        if (/^[A-Za-z0-9_-]{11}$/.test(value)) return value;

        const regExp =
          /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;

        const match = value.match(regExp);
        if (match && match[2]) return match[2];
      }
      return null;
    };

    const videoSrcRaw = computed(() => {
      if (typeof props.video === 'string') return props.video;
      if (props.video?.src) return props.video.src;
      return null;
    });

    const videoSrc = computed(() =>
      !props.video?.id && videoSrcRaw.value
        ? getAssetUrl(videoSrcRaw.value)
        : null,
    );

    const videoId = computed(() => {
      if (props.video?.id) return props.video.id;
      return videoSrcRaw.value ? getYoutubeId(videoSrcRaw.value) : null;
    });

    const start = () => videoRef.value?.play();
    const stop = () => videoRef.value?.pause();

    watch(
      () => props.play,
      (doPLay) => {
        doPLay ? start() : stop();
      },
    );

    return {
      videoId,
      videoSrc,
      videoRef,
      start,
      stop,
    };
  },
});
