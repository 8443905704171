
import {
  defineComponent,
  reactive,
  ref,
  useContext,
  useFetch,
  computed,
  watch,
  useRoute,
} from '@nuxtjs/composition-api';
import {
  useForm,
  useCompanyData,
  useDomain,
  useLanguages,
} from '~/composables';
import { Country, City } from '@webplatform/nuxt-cms-js-sdk';

const PARTNERSHIP_FORM_TYPE = 'asbis_partnership';

interface ConsultationForm {
  type: string;
  lead_source: string;
  source_description: string;
  lead_source_description: string;
  lang: string;
  first_name: string;
  last_name: string;
  email: string;
  mobile_phone: string;
  phone: string;
  company_name: string;
  company_vertical_market: string;
  vertical_market_segment: string | null;
  country: string;
  city: string | null;
  website_url: string;
  core_reason_of_request: string;
  areas_of_interest: string[];
  brands_of_interest: string[];
  business_needs_pain_points_comments: string;
  marketing_yes: boolean;
  generalTerms: boolean;
}

export default defineComponent({
  props: {
    id: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    withGeneralTerm: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const route = useRoute();
    const { currentLanguage } = useLanguages();
    const { domain } = useDomain();
    const { pending: formPending, submit } = useForm();
    const recaptchaClicked = ref(false);
    const {
      i18n,
      $api: { citiesService, countriesService },
      $gtm,
    } = useContext();
    const { focusProductsCategories, cooperationTypes, brands, industries } =
      useCompanyData();

    const countries = ref<Country[]>([]);
    const cities = ref<City[]>([]);
    const formSuccess = ref(false);

    const currentTitle = computed(() =>
      formSuccess.value ? i18n.t('thank_you') : props.title,
    );

    const currentDescription = computed(() =>
      formSuccess.value
        ? i18n.t('our_experts_will_contact_you')
        : props.description,
    );

    const formData = reactive<ConsultationForm>({
      type: PARTNERSHIP_FORM_TYPE,
      lead_source: `${domain}${route.value.path}`,
      source_description: domain,
      lead_source_description: `${domain}${route.value.path}`,
      lang: currentLanguage.value?.code as string,
      first_name: '',
      last_name: '',
      email: '',
      mobile_phone: '',
      phone: '',
      company_name: '',
      company_vertical_market: '',
      vertical_market_segment: '',
      country: '',
      city: '',
      website_url: '',
      core_reason_of_request: '',
      areas_of_interest: [],
      brands_of_interest: [],
      business_needs_pain_points_comments: '',
      marketing_yes: false,
      generalTerms: false,
    });
    const confirmPersonalData = ref(false);
    const confirmAdvs = ref(false);
    const isSubmitButtonDisabled = (invalid: boolean) => {
      if (!props.withGeneralTerm)
        return invalid || !confirmPersonalData.value || formPending.value;
      return (
        invalid ||
        !confirmPersonalData.value ||
        formPending.value ||
        !formData.generalTerms
      );
    };

    const fetchCountries = async () => {
      const { data } = await countriesService.getAllCountries({
        limit: 9999,
        // @ts-ignore
        it4: 1,
      });
      countries.value = data;
    };

    const fetchCities = async (countryIso: string) => {
      const countryId = countries.value.find(
        (country) => country.iso2 === countryIso,
      )?.id;
      const { data } = await citiesService.getAllCities({
        // @ts-ignore
        country_id: countryId,
        limit: 9999,
        // @ts-ignore
        it4: 1,
      });

      cities.value = data;
    };

    useFetch(async () => {
      await fetchCountries();
    });

    watch(
      () => formData.country,
      (newCountry) => {
        fetchCities(newCountry);
      },
    );

    const industrySegments = computed(
      () =>
        industries.find(
          (industry) => industry.id === formData.company_vertical_market,
        )?.segments,
    );

    const submitForm = async () => {
      $gtm.push({ event: 'form-section-partnership' });
      try {
        const { generalTerms, ...restFormData } = formData;
        const data = {
          ...restFormData,
          country: formData.country,
          ...(props.withGeneralTerm && { gdpr_yes: generalTerms }),
        };
        await submit(data);
        confirmPersonalData.value = false;
        formSuccess.value = true;
      } catch (error) {
        console.error(error);
      }
    };

    return {
      currentTitle,
      currentDescription,
      formData,
      confirmPersonalData,
      confirmAdvs,
      formSuccess,
      formPending,
      submitForm,
      recaptchaClicked,
      focusProductsCategories,
      cooperationTypes,
      brands,
      countries,
      cities,
      industries,
      industrySegments,
      isSubmitButtonDisabled,
    };
  },
});
