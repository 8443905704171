
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useMediaAsset } from '~/composables';

export default defineComponent({
  props: {
    slide: {
      type: Object,
      default: undefined,
    },
  },
  setup(props, { emit }) {
    const { getAssetUrl } = useMediaAsset();
    const { title, text, image, video, button, features } = props.slide;

    const backgroundImage = computed(() =>
      !video && image ? getAssetUrl(image) : null,
    );

    const playVideo = () => {
      emit('play', props.slide.videoId);
    };

    return {
      title,
      text,
      video,
      button,
      features,
      backgroundImage,
      getAssetUrl,
      playVideo,
    };
  },
});
