
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useBreakpoint, useMediaAsset } from '~/composables';
import CategoryFeaturesBlocks from '@/domains/categories/components/CategoryFeaturesBlocks.vue';
import CategoryFeaturesBlocksMobile from '@/domains/categories/components/CategoryFeaturesBlocksMobile.vue';

export default defineComponent({
  components: {
    CategoryFeaturesBlocks,
    CategoryFeaturesBlocksMobile,
  },
  props: {
    id: {
      type: String,
      default: undefined,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { isBiggerThanBreakpoint: isDesktop } = useBreakpoint('md');

    const { getAssetUrl } = useMediaAsset();

    const featuresItems = computed(() =>
      (props.items as Record<string, any>[]).map((item) => ({
        ...item,
        image: item.image ? getAssetUrl(item.image) : null,
      })),
    );

    return {
      isDesktop,
      featuresItems,
    };
  },
});
